import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import loadable from "@loadable/component";
import "../../styles/blocks/form.scss";

import Fields from "./form/index.js";
const Parser = loadable(() => import("../parser.js"));

const generateUniqueID = () => {
  return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}

const Form = (props) => {
  const capitalize = (str) => {
    return str[0].toUpperCase()+str.slice(1);
  };
  const [sent, setSent] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [uniqueId, setUniqueId] = useState(props.unique_id || "");
  const [validation, setValidation] = useState(props.form.fields.map(e => ({id:e.id, valid:!e.isRequired})));
  if (typeof window !== "undefined") {
    useEffect(() => {
      if (!uniqueId.length) {
        setUniqueId(generateUniqueID());
      }
    }, []);
  }
  const {
    button,
    //~ enableHoneypot
  } = props.form;
  const requiredIndicator = (props.form.requiredIndicator === "asterisk")
    ? "*"
    : props.form.requiredIndicator;
  const handleValidation = (field, isValid) => {
    // https://stackoverflow.com/a/68568782/8086209
    setValidation(previousValidation => {
      const state = previousValidation.map(item =>{
        if (item.id !== field) {
          return item;
        }
        return {...item, valid: isValid};
      });
      return state;
    });
  };
  const className = (() => {
    let cN = ["gform"];
    if (props.form.labelPlacement.length) {
      cN.push("label-"+props.form.labelPlacement);
    }
    if (props.form.descriptionPlacement.length) {
      cN.push("description-"+props.form.descriptionPlacement);
    }
    return cN.join(" ");
  })();
  const fields = props.form.fields.map(field => {
    let componentName = capitalize(field.type);
    if (componentName === "Number") {
      componentName = "NumberField";
    }
    if (componentName === "Date") {
      componentName = "DateField";
    }
    const FieldComponent = Fields[componentName];
    if (typeof FieldComponent === "undefined") {
      return (<></>);
    }
    return (<FieldComponent
      key={ field.id }
      {...field}
      requiredIndicator={ requiredIndicator }
      validationTrigger={ trigger }
      onValidate={ handleValidation }
      defaultError={ "Ce champ est obligatoire" }
    />);
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setTrigger(window.performance.now());
    if (validation.every(e => e.valid)) {
      let body = (new FormData(e.target));
      let uploaded = {};
      const time = props.form.fields.find(e => e.type === "time");
      if (time) {
        try {
          const timeValue = body.get("input_"+time.id).split(":");
          body.delete("input_"+time.id);
          timeValue.forEach(e => {
            body.append("input_"+time.id+"[]", e);
          });
        } catch {
          (() => {})();
        }
      }
      body.append("is_submit_"+props.form.id, "1");
      if (props.state) {
        body.append("state_"+props.form.id, props.state);
      }
      body.append("gform_submit", props.form.id);
      if (props.form.fields.find(e => e.type === "fileupload")) {
        await Promise.all(props.form.fields.filter(e => e.type === "fileupload").map(async (uploadField) => {
          const temp = new FormData(e.target);
          const uploadInputs = temp.getAll("input_"+uploadField.id);
          await Promise.all(uploadInputs.map(async (uploadInput) => {
            if (!uploadInput.name?.length) {
              return;
            }
            const upload = new FormData();
            body.delete("input_"+uploadField.id);
            console.log(uploadInput);
            const suffix = uploadInput.name.split(".").reverse()[0];
            upload.append("name", [...new Array(16)].map(e => parseInt(Math.random()*1000)).join("")+"."+suffix);
            upload.append("form_id", props.form.id);
            upload.append("field_id", uploadField.id);
            upload.append("original_filename", uploadInput.name);
            upload.append("file", uploadInput);
            upload.append("gform_unique_id", uniqueId);
            let response = await fetch(props.postTarget+props.upload_url.replace(/^\//gi, ""), {
              method: "POST",
              body: upload
            });
            response = await response.json();
            if (response.status === "ok"); {
              if (typeof uploaded["input_"+uploadField.id] !== "undefined") {
                uploaded["input_"+uploadField.id].push(response.data);
              } else {
                uploaded["input_"+uploadField.id] = [response.data];
              }
            }
            return;
          }));
          return;
        }));
        if (Object.keys(uploaded).length) {
          body.append("gform_uploaded_files", JSON.stringify(uploaded));
        }
      }
      let response = await fetch(props.postTarget, {
        method: "POST",
        body
      });
      if (response.ok) {
        setSent(true);
        if (props.redirection) {
          navigate(props.redirection);
        }
      } else {
        console.log(response);
      }
    } else {
      console.error("Form not submitted:",validation); // eslint-disable-line
    }
  };
  return (<>
    {
      sent
        ? <>{
          (props.form.confirmations && Object.keys(props.form.confirmations).length)
            ? <Parser content={ Object.values(props.form.confirmations)[0].message } />
            : <></>
        }</>
        : <form onSubmit={ handleSubmit } noValidate={true} className={ className }>
          <p className="mandatory">Les champs marqués d&apos;une astérisque (*) sont obligatoires</p>
          { fields }
          <div className="submit"><input type="submit" value={ button.text } className="wp-block-button is-style-button-primary" /></div>
        </form>
    }
  </>);
};

export default Form;
