import loadable from "@loadable/component";

const Text = loadable(() => import( "./text.js"));
const Phone = loadable(() => import( "./phone.js"));
const Email = loadable(() => import( "./email.js"));
const Html = loadable(() => import( "./html.js"));
const Textarea = loadable(() => import( "./textarea.js"));
const Section = loadable(() => import( "./section.js"));
const Select = loadable(() => import( "./select.js"));
const Multiselect = loadable(() => import( "./multiselect.js"));
const Checkbox = loadable(() => import( "./checkbox.js"));
const Radio = loadable(() => import( "./radio.js"));
const Name = loadable(() => import( "./name.js"));
const Website = loadable(() => import( "./website.js"));
const NumberField = loadable(() => import( "./number.js"));
const Time = loadable(() => import( "./time.js"));
const DateField = loadable(() => import( "./date.js"));
const Fileupload = loadable(() => import( "./upload.js"));

export default {
  Text,
  Checkbox,
  Textarea,
  Phone,
  Html,
  Email,
  Section,
  Select,
  Multiselect,
  Name,
  Radio,
  Website,
  NumberField,
  Time,
  DateField,
  Fileupload
};
